<template>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    title="费用类型"
    v-model="dialogVisible"
    width="450px"
  >
    <el-form>
      <el-form-item label="费用类型">
        <el-select v-model="types" style="width: 200px" filterable size="small">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="typeClick()" size="small"
          >确 定</el-button
        >
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref } from "vue";
import { saveEmployees } from "@/api/salary";
import { COST_TYPE_OPTION } from "@/utils/commonData";
const emit = defineEmits(["typeChange"]);
const options = COST_TYPE_OPTION;
const dialogVisible = ref(false);
const types = ref("");
const init = () => {
  dialogVisible.value = true;
};
const typeClick = () => {
  dialogVisible.value = false;
  emit("typeChange", types.value);
};
defineExpose({
  init,
});
</script>

<style scoped lang="scss"></style>
